@import "@styles/theme";

.root {
  @include for-size(mobile-portrait) {
    margin-top: 0px;
  }
  @include for-size(mobile-landscape) {
    margin-top: 0px;
  }
  @include for-size(tablet-portrait) {
    margin-top: 0px;
  }
  margin-top: -30px;
  section {
    margin-bottom: 64px;
  }
  .topbanner {
    margin-bottom: 108px;
    position: relative;
    @include column();
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(80vh);
    width: 100%;
    @include for-size(mobile-portrait) {
      min-height: calc(max(90vh, 720px));
    }
    @include for-size(mobile-landscape) {
      justify-content: flex-start;
      min-height: calc(max(90vh, 720px));
    }
    @include for-size(tablet-portrait) {
      align-items: flex-start;
      min-height: calc(80vh);
    }
    @include for-size(tablet-landscape) {
      min-height: 80vh;
      align-items: flex-start;
    }
    .homevideo {
      position: absolute;
      object-position: center;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .homecontent {
      position: absolute;
      z-index: 1;
      @include column();
      justify-content: center;
      @include for-size(mobile-portrait) {
        justify-content: flex-start;
      }
      @include for-size(mobile-landscape) {
        justify-content: flex-start;
      }

      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.42);
      .info {
        @include column();
        @include paddingVertical(16);
        padding-left: 4%;
        @include for-size(mobile-portrait) {
          justify-self: flex-start;
          max-width: 90%;
          @include paddingHorizontal(32);
        }
        @include for-size(mobile-landscape) {
          max-width: 80%;
          padding-left: 32px;
          padding-top: 32px;
        }
        @include for-size(tablet-portrait) {
          padding-left: 64px;
        }
        @include for-size(tablet-landscape) {
          padding-left: 64px;
        }
        max-width: 520px;
        h1 {
          color: $color-white;
          margin-bottom: 1rem;
        }
        h2 {
          color: $color-white;
          margin-bottom: 1rem;
        }
        p {
          color: $color-white;
        }
      }
    }

    .dropdowncontainer {
      z-index: 100;
      display: flex;
      justify-content: flex-end; /* Align items to the end of the container */
      position: absolute;
      @include row();
      justify-content: center;
      bottom: -42px;
      left: 0;
      right: 0;
      @include for-size(mobile-portrait) {
        bottom: -64px;
      }
      @include for-size(mobile-landscape) {
        bottom: -64px;
      }
      @include for-size(tablet-portrait) {
        @include marginHorizontal(16);
      }
      @include for-size(tablet-landscape) {
      }
    }
  }

  .productosDestacados {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 50%,
      $color-gray50 50%,
      $color-gray50 100%
    );
    @include column();

    h2 {
      text-align: center;
      color: $color-black;
      @include marginVertical(16);
    }
    .wrapper {
      height: 480px;
    }
  }

  .nuestrosClientes {
    background-color: $color-white;
    h2 {
      text-align: center;
      @include marginVertical(16);
    }
    .opinionsContainer {
      .opinion {
        @include grid();
        grid-template-columns: 60% 40%;

        @include for-size(mobile-portrait) {
          grid-template-columns: 1fr;
        }
        @include for-size(mobile-landscape) {
          grid-template-columns: 1fr;
        }
        @include for-size(tablet-portrait) {
          grid-template-columns: 1fr;
        }
        .video {
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .datacontainer {
          @include column();
          justify-content: center;
          align-items: center;
          .descriptionContainer {
            @include row();
            @include marginVertical(16);
            @include paddingHorizontal(16);
            @include paddingVertical(16);
            justify-content: center;
            width: 90%;
            max-width: 420px;
            background-color: $color-white;
            border-radius: 8px;
            box-shadow: 0px 0px 16px 0 rgba(0, 0, 0, 0.12);
            svg {
              height: 64px;
              width: 64px;
              @include marginHorizontal(8);
            }
            .textcontainer {
              text-align: center;
              p {
                margin-bottom: 24px;
              }
            }
          }
        }
      }
    }
  }

  .comunidadManvert {
    background-color: $color-white;
    @include column();
    width: 100%;
    h2 {
      text-align: center;
      @include marginVertical(8);
    }
    .topRow {
      @include row();
      flex-wrap: wrap;
      @include marginVertical(32);
      @include for-size(mobile-portrait) {
        @include column();
        gap: 32px;
        align-items: center;
      }
      @include for-size(mobile-landscape) {
        @include column();
        gap: 32px;
        align-items: center;
      }
      @include for-size(tablet-portrait) {
        justify-content: space-around;
      }
      @include for-size(tablet-landscape) {
        justify-content: space-around;
      }
      @include for-size(desktop) {
        justify-content: space-around;
      }

      .item {
        @include row();
        @include bounceTop();
        height: 100%;
        @include for-size(mobile-portrait) {
          @include column();
          width: 85vw;
          max-width: 320px;
        }
        @include for-size(mobile-landscape) {
          @include column();
          max-width: 380px;
        }
        @include for-size(tablet-portrait) {
          width: 85vw;
          max-width: 920px;
          height: 320px;
          margin-bottom: 32px;
        }
        @include for-size(tablet-landscape) {
          width: 48vw;
          max-width: 920px;
          height: 320px;
        }
        @include for-size(desktop) {
          width: 40vw;
          max-width: 920px;
          height: 380px;
        }
        h2 {
          @include marginVertical(8);
          text-align: left;
        }
        .left {
          @include for-size(tablet-landscape) {
            flex-basis: 40%;
          }
          @include for-size(desktop) {
          }
          flex-basis: 50%;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .right {
          @include for-size(tablet-landscape) {
            flex-basis: 60%;
          }
          @include for-size(desktop) {
          }
          flex-basis: 50%;
          color: $color-black;
          padding: 32px 8px 8px 32px;
          .frame {
            margin-bottom: 16px;
            width: 64px;
            height: 3px;
            background-color: $color-primary;
          }
        }
      }
    }
  }

  .croptologySection,
  .biovertMundoSection {
    background-color: $color-white;
    h2 {
      text-align: center;
      margin-bottom: 32px;
    }
    @include column();
    .item {
      position: relative;
      width: calc(min(100%, 1440px));
      margin: 0 auto;
      justify-content: center;

      @include row();

      @include for-size(mobile-portrait) {
        @include column();
        &.inverse {
          @include columnreverse();
          align-items: center;
        }
      }
      @include for-size(mobile-landscape) {
        @include column();
        &.inverse {
          align-items: center;
          @include columnreverse();
        }
      }
      @include for-size(tablet-portrait) {
        @include column();
        &.inverse {
          align-items: center;
          @include columnreverse();
        }
      }
      align-items: center;
      text-decoration: none;
      .card {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        z-index: 1;
        @include column();
        @include paddingHorizontal(32);
        @include paddingVertical(32);
        color: black;
        background-color: $color-white;
        max-width: 400px;
        @include for-size(mobile-portrait) {
          position: static;
          width: calc(100% - 64px);
          @include paddingHorizontal(32);
          max-width: none;
        }
        @include for-size(mobile-landscape) {
          position: static;
          margin-bottom: -16px;
          width: calc(min(100% - 64px, 420px));
          @include paddingHorizontal(32);
          max-width: none;
        }
        @include for-size(tablet-portrait) {
          position: static;
          margin-bottom: -16px;
          width: calc(min(100% - 64px, 420px));
          @include paddingHorizontal(32);
          max-width: none;
        }
        h1 {
          @include marginVertical(8);
          text-decoration: none;
        }
        p {
          @include marginVertical(16);
        }
      }
      img {
        width: 75%;
        max-height: 700px;
        object-fit: contain;
        object-position: center;

        @include for-size(mobile-portrait) {
          width: 100%;
          max-height: 50vh;
        }
        @include for-size(mobile-landscape) {
          width: 100%;
          max-height: 50vh;
        }
        @include for-size(tablet-portrait) {
          width: 100%;
        }
        @include for-size(tablet-landscape) {
          &.normal {
            margin-right: none;
            margin-left: -5%;
          }
          &.inverse {
            margin-right: -5%;
            margin-left: none;
          }
        }
        @include for-size(desktop) {
          &.normal {
            margin-right: none;
            margin-left: -5%;
          }
          &.inverse {
            margin-right: -5%;
            margin-left: none;
          }
        }
      }
    }
  }
  .noticiasSection {
    background-color: $color-white;
    @include column();
    h2 {
      @include marginVertical(16);
      text-align: center;
    }
    .todosLink {
      color: $color-primary;
      @include strong();
      font-family: $font-family-body;
      @include marginVertical(8);
      @include paddingHorizontal(16);
      @include paddingVertical(8);
      align-self: center;
    }
    .noticiasWrapper {
      @include grid();
      @include paddingVertical(32);
      width: 100%;

      max-width: 1290px;
      margin: 0 auto;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 8px;
      @include for-size(mobile-portrait) {
        grid-template-columns: 1fr;
        max-width: 90vw;
      }
      @include for-size(mobile-landscape) {
        grid-template-columns: 1fr 1fr;
      }
      @include for-size(tablet-portrait) {
        grid-template-columns: 1fr 1fr;
      }
      @include for-size(tablet-landscape) {
      }
    }
  }
}
